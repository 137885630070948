<template>
  <div class="content" v-cloak>

    <div class="text" v-html='msg.content'  >　
　</div>



  </div>
</template>
<script>
import colleges from "../../plugin/colleges";
import qs from "qs";
export default {
    data() {
    return {
    msg:''
    };
  },
    mounted(){
       this.details() 
  },
  methods:{
      async details() {
      const {data} = await colleges.post('/user/userment')
      console.log(data)
      this.msg=data
    },

  }
};
</script>
<style lang="less" >
.content {
  overflow: scroll;
}
.text  {
  font-size:0.5rem;
  margin:0.47rem;
  line-height: 1.5;
}
p  {
   background-color: #ebeff2!important;
}
</style>